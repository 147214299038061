<script>
import ScreenContainer from "commonsfrontend/src/maker/vue-files/workspace/sections/screen/ScreenContainer.vue";
import smartphone from "./smartphone";
import installedPlugins from "./installedPlugins";

export default {
  extends: ScreenContainer,
  components: {
    // osSelect,
    // pagination,
    smartphone,
    installedPlugins
  }
};
</script>
