export class Timer extends Phaser.GameObjects.Container {
  constructor(scene: Phaser.Scene, x, y) {
    super(scene, x, y);
    const text = new Phaser.GameObjects.Text(scene, 0, 0, "0", {
      fontSize: "30px",
      backgroundColor: `#000`,
      color: `#fff`
    });
    scene.add.existing(this);
    this.add(text);
    this.setSize(text.width, text.height).setDisplaySize(
      text.width,
      text.height
    );
    text.setPosition(-text.width / 2, -text.height / 2);
    this.setInteractive();
    scene.input.setDraggable(this);
  }
}
