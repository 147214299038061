<template>
  <component :is="mode" ref="ActiveDesignContainer" />
</template>

<script>
import DesignSettings from "./DesignSettings";
import SoundSettings from "./SoundSettings";
export default {
  components: { DesignSettings, SoundSettings },
  data() {
    return {
      mode: "design-settings"
    };
  },
  created() {
    this.$root.$on("workspace/settings::targetId", targetId => {
      if (this.mode === "design-settings") return;
      this.mode = "design-settings";
      this.$nextTick(() => {
        this.$refs.ActiveDesignContainer.selectedObject = this.$refs.ActiveDesignContainer.showingDesignPage[
          targetId
        ];
      });
    });

    this.$root.$on("workspace/settings::plugin-sound", () => {
      if (this.mode === "sound-settings") return;
      this.mode = "sound-settings";
    });
  }
};
</script>

<style>
</style>