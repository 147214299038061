<script>
import RunMode from "commonsfrontend/src/maker/vue-files/workspace/sections/screen/modes/run.vue";
import { mapGetters } from "vuex";

export default {
  extends: RunMode,
  data() {
    return {
      iframePath: "game-preview",
    };
  },
  computed: {
    ...mapGetters("workspace/session", ["appJson"]),
  },
};
</script>

<style>
/* To let run-mode div to overlay edit-mode */
.run-mode {
  position: absolute;
  top: 0;
}
</style>
