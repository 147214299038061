import { VNode } from "vue";

export default class DesignComponent {
  info?: {
    title?: string;
    img?: string;
    hidden?: boolean;
    disabled?: boolean;
  };

  structure: any;

  settings: any;

  constructor({
    info,
    structure,
    settings
  }: {
    info?: {
      hidden?: boolean;
      title?: string;
      img?: string;
      disabled?: boolean;
    };
    structure: any;
    settings?: any;
  }) {
    if (info) this.info = info;

    const exactName = structure.name || structure.id;

    if (structure) {
      this.structure = {
        ...structure,
        exactName
        //isRootInsert: false,
        //isComment: false
      };
    }

    if (this.structure.design) {
      this.structure.design = {
        ...structure.design,
        tags: [
          ...(structure.design.tags ? structure.design.tags : []),
          /*
          @note no need for now because check uses tags field.
          structure.design.type
            ? structure.design.type + "-" + exactName
            : undefined*/
          exactName
        ].filter(tag => typeof tag === "string")
      };
    }

    /*
    if (!this.structure.children && this.info?.title) {
      this.structure.children = [
        {
          text: this.info.title,
          isRootInsert: false,
          isComment: false
        }
      ];
    }*/

    if (settings) {
      this.settings = settings;
    }
  }
}
