// Ref: https://stackoverflow.com/a/13828792
function cmp(x, y) {
  if (x > y) {
    return 1;
  } else if (x < y) {
    return -1;
  } else {
    return 0;
  }
}
function turn(p, q, r) {
  return cmp((q[0] - p[0]) * (r[1] - p[1]) - (r[0] - p[0]) * (q[1] - p[1]), 0);
}
function dist(p, q) {
  var dx = q[0] - p[0];
  var dy = q[1] - p[1];
  return dx * dx + dy * dy;
}
function next_hull_pt(points, p) {
  var q = p,
    r,
    t;
  for (var i = 0; i < points.length; i++) {
    r = points[i];
    t = turn(p, q, r);
    if (t == -1 || (t == 0 && dist(p, r) > dist(p, q))) {
      q = r;
    }
  }
  return q;
}

export default function convex_hull(points) {
  var left, point;
  for (var i = 0; i < points.length; i++) {
    point = points[i];
    if (!left || point[0] < left[0]) {
      left = point;
    }
  }
  var hull = [left],
    p,
    q;
  for (var i = 0; i < hull.length; i++) {
    p = hull[i];
    q = next_hull_pt(points, p);
    if (q[0] != hull[0][0] || q[1] != hull[0][1]) {
      hull.push(q);
    }
  }
  hull.push(left);
  return hull;
}
