import DesignElement from "../designElement";
import Phaser from "phaser";
import settings from "../settings";
let sources = [
  ["jungle", "/img/backgrounds/jungle.png", "vertical"],
  ["icedriver", "/img/backgrounds/icedriver.png", "vertical"],
  ["frozen2", "/img/backgrounds/frozen2.svg", "vertical"],
  ["frozen", "/img/backgrounds/frozen.png", "vertical"],
  ["trees1", "/img/backgrounds/jungle2-1.jpg", "vertical"],
  ["sky", "/img/backgrounds/sky.png", "vertical"],
  ["trees2", "/img/backgrounds/jungle2-2.jpg", "vertical"],
  ["trees3", "/img/backgrounds/jungle2-3.jpg", "vertical"],
  ["tree", "/img/backgrounds/jungle3-1.jpg", "vertical"],
  ["grass1", "/img/backgrounds/jungle3-2.jpg", "vertical"],
  ["grass2", "/img/backgrounds/jungle3-3.jpg", "vertical"],
  ["jungle1", "/img/backgrounds/jungle3-4.jpg", "horizontal"],
  ["sugar", "/img/backgrounds/sugar.png", "horizontal"],
  ["jungle2", "/img/backgrounds/jungle2.jpg", "horizontal"]
];

export default [
  ...sources.map(
    source =>
      new DesignElement({
        info: {
          title: source[0],
          img: source[1]
        },
        structure: {
          name: source[0],
          src: source[1],
          orientation: source[2],
          design: {
            type: "background"
          }
        },
        settings: [
          ...settings.position,
          ...settings.size,
          ...settings.alignment,
          ...settings.filter,
          ...settings.parallax
        ]
      })
  )
];
