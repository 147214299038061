<template>
  <div class="tabbed-content-design">
    <div
      :is="designElement"
      v-for="(title, designElement) in $root.settings.designElementsOrder"
      :key="designElement"
      :title="title"
    ></div>
  </div>
</template>

<script>
import backgrounds from "./backgrounds";
import assets from "./assets";
import components from "./components";
import sounds from "./sounds";

export default {
  components: {
    backgrounds,
    assets,
    components,
    sounds
  }
};
</script>

<style lang="scss" scoped>
@import "~commonsfrontend/src/maker/style/scss/vars.scss";

.tabbed-content-design {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  overflow: hidden;
}
</style>
