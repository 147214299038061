import DesignElement from "../designElement";
import settings from "../settings";

export default [
  new DesignElement({
    info: {
      title: "boy",
      img: "/img/assets/boy.png"
    },
    structure: {
      name: "boy",
      src: "/img/assets/boy.png",
      width: 95,
      height: 99,
      design: {
        type: "asset",
        tags: ["character", "boy"]
      },
      anims: {
        push: [
          "/img/assets/animation_character/char_0001.png",
          "/img/assets/animation_character/char_0002.png",
          "/img/assets/animation_character/char_0003.png",
          "/img/assets/animation_character/char_0004.png",
          "/img/assets/animation_character/char_0005.png",
          "/img/assets/animation_character/char_0006.png",
          "/img/assets/animation_character/char_0007.png",
          "/img/assets/animation_character/char_0008.png",
          "/img/assets/animation_character/char_0009.png",
          "/img/assets/animation_character/char_0010.png"
        ]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter,
      settings.animations("push")
    ]
  }),
  // Polar Bear
  new DesignElement({
    info: {
      title: "Polar Bear",
      img: "/img/assets/Polar/Polar/polar_bear.png"
    },
    structure: {
      name: "polarbear",
      src: "/img/assets/Polar/Polar/polar_bear.png",
      width: 65,
      height: 65,
      design: {
        type: "asset",
        tags: ["character"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter,
      settings.animations("jetpack", "destroy")
    ]
  }),
  // Penguin
  new DesignElement({
    info: {
      title: "Penguin",
      img: "/img/assets/Polar/Polar/penguin.png"
    },
    structure: {
      name: "penguin",
      src: "/img/assets/Polar/Polar/penguin.png",
      width: 35,
      height: 42.5,
      design: {
        type: "asset",
        tags: ["character"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter,
      settings.animations("jetpack", "destroy")
    ]
  }),
  new DesignElement({
    info: {
      title: "rocket",
      img: "/img/assets/rocket/rocket1.png"
    },
    structure: {
      name: "rocket",
      src: "/img/assets/rocket/rocket2.png",
      width: 35,
      height: 60,
      design: {
        type: "asset",
        tags: ["character", "rocket"]
      },
      anims: {
        fire: [
          "/img/assets/rocket/rocket2.png",
          "/img/assets/rocket/rocket3.png",
          "/img/assets/rocket/rocket4.png"
        ]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter,
      settings.animations("fire")
    ]
  }),
  // mini ice
  new DesignElement({
    info: {
      title: "Mini Ice",
      img: "/img/assets/Polar/Polar/ice_penguin.png"
    },
    structure: {
      name: "miniice",
      src: "/img/assets/Polar/Polar/ice_penguin.png",
      width: 35,
      height: 42.5,
      design: {
        type: "asset",
        tags: ["item"]
      },
      values: {
        shape: "miniice"
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter,
      settings.animations("jetpack", "destroy")
    ]
  }),
  // Ice Circular
  new DesignElement({
    info: {
      title: "Ice Circular",
      img: "/img/assets/Polar/Polar/ice_circular.png"
    },
    structure: {
      name: "icecircular",
      src: "/img/assets/Polar/Polar/ice_circular.png",
      width: 75,
      height: 75,
      design: {
        type: "asset",
        tags: ["item"]
      },
      values: {
        shape: "circular"
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  // Ice Square
  new DesignElement({
    info: {
      title: "Ice Square",
      img: "/img/assets/Polar/Polar/ice_square.png"
    },
    structure: {
      name: "icesquare",
      src: "/img/assets/Polar/Polar/ice_square.png",
      width: 75,
      height: 75,
      design: {
        type: "asset",
        tags: ["item"]
      },
      values: {
        shape: "square"
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  // Ice Hexagon
  new DesignElement({
    info: {
      title: "Ice Hexagon",
      img: "/img/assets/Polar/Polar/ice_hexagon.png"
    },
    structure: {
      name: "icehexagon",
      src: "/img/assets/Polar/Polar/ice_hexagon.png",
      width: 85.5,
      height: 78.8,
      design: {
        type: "asset",
        tags: ["item"]
      },
      values: {
        shape: "hexagon"
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  // Water Circular
  new DesignElement({
    info: {
      title: "Water Circular",
      img: "/img/assets/Polar/Polar/water_circular.png"
    },
    structure: {
      name: "watercircular",
      src: "/img/assets/Polar/Polar/water_circular.png",
      width: 75,
      height: 75,
      design: {
        type: "asset",
        tags: ["item"]
      },
      values: {
        shape: "circular"
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  // Water Square
  new DesignElement({
    info: {
      title: "Water Square",
      img: "/img/assets/Polar/Polar/water_square.png"
    },
    structure: {
      name: "watersquare",
      src: "/img/assets/Polar/Polar/water_square.png",
      width: 75,
      height: 75,
      design: {
        type: "asset",
        tags: ["item"]
      },
      values: {
        shape: "square"
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  // Water Hexagon
  new DesignElement({
    info: {
      title: "Water Hexagon",
      img: "/img/assets/Polar/Polar/water_hexagon.png"
    },
    structure: {
      name: "waterhexagon",
      src: "/img/assets/Polar/Polar/water_hexagon.png",
      width: 85.5,
      height: 78.8,
      design: {
        type: "asset",
        tags: ["item"]
      },
      values: {
        shape: "hexagon"
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  // Red Arrow Left
  new DesignElement({
    info: {
      title: "Arrow Left",
      img: "/img/assets/Polar/Polar/arrow_left.png"
    },
    structure: {
      name: "arrowleft",
      src: "/img/assets/Polar/Polar/arrow_left.png",
      width: 36.3,
      height: 28.8,
      design: {
        type: "asset",
        tags: ["item"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  // Red Arrow Right
  new DesignElement({
    info: {
      title: "Arrow Right",
      img: "/img/assets/Polar/Polar/arrow_right.png"
    },
    structure: {
      name: "arrowright",
      src: "/img/assets/Polar/Polar/arrow_right.png",
      width: 36.3,
      height: 28.8,
      design: {
        type: "asset",
        tags: ["item"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  new DesignElement({
    info: {
      title: "enemy",
      img: "/img/assets/jump-game-kit/enemy1/fly/1enemy0001.png"
    },
    structure: {
      name: "enemy",
      src: "/img/assets/jump-game-kit/enemy1/fly/1enemy0001.png",
      width: 100,
      height: 40,
      design: {
        type: "asset",
        tags: ["item"]
      },

      anims: {
        fly: [
          "/img/assets/jump-game-kit/enemy1/fly/1enemy0001.png",
          "/img/assets/jump-game-kit/enemy1/fly/1enemy0002.png",
          "/img/assets/jump-game-kit/enemy1/fly/1enemy0003.png",
          "/img/assets/jump-game-kit/enemy1/fly/1enemy0004.png",
          "/img/assets/jump-game-kit/enemy1/fly/1enemy0005.png",
          "/img/assets/jump-game-kit/enemy1/fly/1enemy0006.png"
        ]
      }
    },

    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter,
      settings.animations("fly")
    ]
  }),
  new DesignElement({
    info: {
      title: "asteroid",
      img: "/img/assets/asteroid_stone.png"
    },
    structure: {
      name: "asteroid",
      src: "/img/assets/asteroid_stone.png",
      width: 50,
      height: 50,
      design: {
        type: "asset",
        tags: ["item"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  new DesignElement({
    info: {
      title: "star",
      img: "/img/assets/star.png"
    },
    structure: {
      name: "star",
      src: "/img/assets/star.png",
      width: 30,
      height: 30,
      design: {
        type: "asset",
        tags: ["item"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  new DesignElement({
    info: {
      title: "cannon",
      img: "/img/assets/cannon.png"
    },
    structure: {
      name: "cannon",
      src: "/img/assets/cannon.png",
      width: 95,
      height: 99,
      design: {
        type: "container",
        tags: ["item"],
        width: 175,
        height: 100,
        list: [
          {
            type: "sprite",
            name: "cannonTop",
            src: "/img/assets/animation_cannon/cannon_0001.png",
            scale: 0.3,
            position: [20, -30],
            anims: {
              boom: [
                "/img/assets/animation_cannon/cannon_0001.png",
                "/img/assets/animation_cannon/cannon_0002.png",
                "/img/assets/animation_cannon/cannon_0003.png",
                "/img/assets/animation_cannon/cannon_0004.png",
                "/img/assets/animation_cannon/cannon_0005.png",
                "/img/assets/animation_cannon/cannon_0006.png"
              ]
            }
          },
          {
            name: "cannonWire",
            type: "image",
            src: "/img/assets/cannon_wire.png",
            scale: 0.3,
            position: [-55, 25]
          },
          {
            name: "cannonBase",
            type: "image",
            src: "/img/assets/cannon_base.png",
            scale: 0.3,
            position: [20, 20]
          }
        ]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter,
      settings.animations("boom")
    ]
  }),
  new DesignElement({
    info: {
      title: "bubbles",
      img: "/img/assets/bubbles.png"
    },
    structure: {
      name: "bubbles",
      src: "/img/assets/bubbles.png",
      width: 260,
      height: 245,
      design: {
        type: "asset",
        tags: ["item"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  new DesignElement({
    info: {
      title: "obstacle1",
      img: "/img/assets/obstacle1.png"
    },
    structure: {
      name: "obstacle1",
      src: "/img/assets/obstacle1.png",
      width: 75,
      height: 20,
      design: {
        type: "asset",
        tags: ["item"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  new DesignElement({
    info: {
      title: "obstacle2",
      img: "/img/assets/obstacle2.png"
    },
    structure: {
      name: "obstacle2",
      src: "/img/assets/obstacle2.png",
      width: 75,
      height: 20,
      design: {
        type: "asset",
        tags: ["item"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  new DesignElement({
    info: {
      title: "chicken",
      img: "/img/assets/chicken.png"
    },
    structure: {
      name: "chicken",
      src: "/img/assets/chicken.png",
      width: 95,
      height: 99,
      anims: {
        destroy: ["/img/assets/chicken-destroy.png"],
        jetpack: [
          "/img/assets/chicken-jetpack1.png",
          "/img/assets/chicken-jetpack2.png",
          "/img/assets/chicken-jetpack3.png"
        ]
      },
      design: {
        type: "asset",
        tags: ["character"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter,
      settings.animations("jetpack", "destroy")
    ]
  }),
  new DesignElement({
    info: {
      title: "jetpack",
      img: "/img/assets/jetpack.png"
    },
    structure: {
      name: "jetpack",
      src: "/img/assets/jetpack.png",
      width: 45,
      height: 50,
      design: {
        type: "asset",
        tags: ["item"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  new DesignElement({
    info: {
      title: "arrow",
      img: "/img/assets/arrow.png"
    },
    structure: {
      name: "arrow",
      src: "/img/assets/arrow.png",
      width: 45,
      height: 35,
      design: {
        type: "asset",
        tags: ["item"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  new DesignElement({
    info: {
      title: "round",
      img: "/img/assets/round.png"
    },
    structure: {
      name: "round",
      src: "/img/assets/round.png",
      width: 45,
      height: 45,
      design: {
        type: "asset",
        tags: ["item"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  new DesignElement({
    info: {
      title: "rocket2",
      img: "/img/assets/jump-game-kit/objects/rocket.png"
    },
    structure: {
      name: "rocket2",
      src: "/img/assets/jump-game-kit/objects/rocket.png",
      width: 45,
      height: 80,
      design: {
        type: "asset",
        tags: ["item"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  new DesignElement({
    info: {
      title: "coin",
      img: "/img/assets/jump-game-kit/objects/coin.png"
    },
    structure: {
      name: "coin",
      src: "/img/assets/jump-game-kit/objects/coin.png",
      width: 45,
      height: 45,
      design: {
        type: "asset",
        tags: ["item"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  new DesignElement({
    info: {
      title: "heart",
      img: "/img/assets/jump-game-kit/objects/heart.png"
    },
    structure: {
      name: "heart",
      src: "/img/assets/jump-game-kit/objects/heart.png",
      width: 45,
      height: 45,
      design: {
        type: "asset",
        tags: ["item"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  }),
  new DesignElement({
    info: {
      title: "trampoline",
      img: "/img/assets/jump-game-kit/objects/trampolineOn.png"
    },
    structure: {
      name: "trampolineOn",
      src: "/img/assets/jump-game-kit/objects/trampolineOn.png",
      width: 45,
      height: 45,
      design: {
        type: "asset",
        tags: ["item"]
      }
    },
    settings: [
      ...settings.position,
      ...settings.size,
      ...settings.alignment,
      ...settings.filter
    ]
  })
  /*
  new DesignElement({
    info: {},
    structure: {}
  }),*/
];
