import * as Phaser from "phaser";
/**
 * A tooltip Game Object
 */

export class Tooltip extends Phaser.GameObjects.Text {
  _content: any;
  TIMEOUT = 50;
  static EVENTS = {
    ACTIVATE: Symbol("ACTIVATE"),
    DEACTIVATE: Symbol("DEACTIVATE")
  };
  /**
   *
   * @param scene The Scene to which this Game Object belongs. A Game Object can only belong to one Scene at a time.
   * @param object The game object for tooltip.
   * @param {content} Tooltip content
   */
  constructor(scene: Phaser.Scene, object: any, content: string) {
    super(scene, 0, 0, content, {
      backgroundColor: "#fff",
      color: "#000",
      fontSize: "2.5vh",
      padding: { left: 10, right: 10, top: 7, bottom: 7 }
    });

    this.scene.add.existing(this);
    this.setVisible(false);
    this._content = content;
    let interval;

    // this.scene.input.enableDebug(object);

    this.addListener(Tooltip.EVENTS.ACTIVATE, () => {
      object.on("pointerover", input => {
        this.setVisible(true);
        interval = setInterval(() => {
          this.x = scene.cameras.main.scrollX + input.x - this.width / 2;
          this.y = scene.cameras.main.scrollY + input.y - this.height / 2 - 10;
        }, this.TIMEOUT);
      });

      object.on("pointerout", () => {
        this.setVisible(false);
        clearInterval(interval);
      });
    });

    this.addListener(Tooltip.EVENTS.DEACTIVATE, () => {
      clearInterval(interval);
      object.off("pointerout");
      object.off("pointerover");
    });

    this.setVisible(false);
    this.setDepth(20000);
  }

  /**
   * @param $vue Vue instance
   * @param activate Vuejs event name for activate tooltip
   * @param deactivate Vuejs event name for deactivate tooltip
   */
  public bindToVue($vue: any, activate: string, deactivate: string) {
    if (activate)
      $vue.$on(activate, () => {
        this.emit(Tooltip.EVENTS.ACTIVATE);
      });
    if (deactivate)
      $vue.$on(deactivate, () => {
        this.emit(Tooltip.EVENTS.DEACTIVATE);
      });
  }
}
