/**
 * Bubbles Group
 */
import * as _ from "lodash";
import { times } from "lodash";
import outerBorder from "../utils/outerPoints";
import { AsyncImageLoader } from "./core/asyncImageLoader";
export default class ColorGridClass extends Phaser.GameObjects.Container {
  questionSet: string[] = [];
  colors: number[] = [
    0xc0392b,
    0xe74c3c,
    0x9b59b6,
    0x8e44ad,
    0x2980b9,
    0x3498db,
    0x1abc9c,
    0x16a085,
    0x8fb502,
    0xb894c1,
    0xa6acaf,
    0x34495e,
    0xdeff1d,
    0xe86900,
    0xffb000,
    0xff00c2,
    0xff0086,
    0x3d2244
  ];
  constructor(scene: Phaser.Scene) {
    super(scene, scene.cameras.main.centerX, scene.cameras.main.centerY);
    this.setSize(
      this.scene.cameras.main.displayWidth * 0.8,
      this.scene.cameras.main.displayHeight * 0.6
    );
    this.setX(this.scene.cameras.main.centerX);
    this.setY(this.scene.cameras.main.centerY);
    this.setInteractive();
    this.scene.input.setDraggable(this);
    this.scene.add.existing(this);
    this.type = "imagepuzzle";

  }

  async load() {
    const uploads = [1, 2, 3, 4, 5, 6, 7, 8, 9, "10"].map(async i =>
      await new AsyncImageLoader(this.scene, {
        key: "shape" + i,
        src: window.location.origin + "/img/assets/shape" + i + ".png"
      }).start()
    );

    return Promise.all(uploads).then(() => {
      for (const i of [1, 2, 3, 4, 5, 6, 7, 8, 9, "10"])
        this.questionSet.push("shape" + i);
      this.initialize(4, 4);
    });
  }

  initialize(row, col) {
    this.setData("row", row);
    this.setData("col", col);

    this.removeAll(true);

    let unitWidth = this.width / col;
    let unitHeight = this.height / row;
    let designTopLeftX = -this.width / 2 + unitWidth / 2;
    let designTopLeftY = -this.height / 2 + unitHeight / 2;

    let imageCouple = [];

    for (let i = 0; i < row * col; i = i + 2) {
      const random = Number(Math.random() * this.questionSet.length)
        .toString()
        .split(".")[0];
      imageCouple[i] = this.questionSet[random];
      imageCouple[i + 1] = this.questionSet[random];
    }
    let k = 0;
    for (let i = 0; i < row; i++) {
      for (let j = 0; j < col; j++) {
        const x = designTopLeftX + j * unitWidth + j * 2;
        const y = designTopLeftY + i * unitHeight + i * 2;

        const random = Number(
          Number(Math.random() * imageCouple.length)
            .toString()
            .split(".")[0]
        );
        const rectangle = new Phaser.GameObjects.Rectangle(
          this.scene,
          x,
          y,
          unitWidth - 4,
          unitHeight - 4,
          this.colors[0]
        ).setDepth(300);
        const question = new Phaser.GameObjects.Image(
          this.scene,
          x,
          y,
          imageCouple[random]
        );
        imageCouple.splice(random, 1);
        question.setAlpha(0);
        question.setDisplaySize(unitWidth - 4, unitHeight - 4);

        this.add(rectangle);
        this.add(question);

        // Add GameObject to the scene.
        // this.scene.add.existing(rectangle);
        ++k;
      }
    }

    let previousRect, previousImage;
    const interval = setInterval(() => {
      const rectangles = this.list.filter(go => go.type === "Rectangle");
      const images = this.list.filter(go => go.type === "Image");

      const random = Number(Math.random() * rectangles.length)
        .toString()
        .split(".")[0];
      const randomRect = rectangles[random];
      const randomImage = images[random];

      randomRect.setAlpha(randomRect.alpha ? 0 : 1);
      randomImage.setAlpha(randomImage.alpha ? 0 : 1);

      if (previousRect) previousRect.setAlpha(previousRect.alpha ? 0 : 1);
      if (previousImage) previousImage.setAlpha(previousRect.alpha ? 0 : 1);

      previousRect = randomRect;
      previousImage = randomImage;
      this.once("destroy", () => {
        clearInterval(interval)
      })
    }, 550);
  }
}
