<script>
import SettingsContainer from "commonsfrontend/src/maker/vue-files/workspace/sections/settings/SettingsContainer.vue";
import elements from "@/types/design-elements/index.ts";

export default {
  extends: SettingsContainer,
  data() {
    return {
      selectedObject: null
    };
  },
  computed: {
    // design-elements-related
    info() {
      if (!this.selectedObject) return {};
      const designElement = elements.find(element => {
        if (element?.structure)
          return (
            element.structure.name === this.selectedObject.name.split("-")[0]
          );
      });
      return designElement ? designElement.info : {};
    },
    // design-elements-related
    settings() {
      if (!this.selectedObject) return [];
      const designElement = elements.find(element => {
        if (element?.structure)
          return (
            element.structure.name === this.selectedObject.name.split("-")[0]
          );
      });
      return designElement ? designElement.settings : [];
    },
    thumbnail() {
      return this.selectedObject?.getData("imgSrc");
    },
    objectId() {
      return this.selectedObject?.name;
    },
    showDeleteButton() {
      return this.selectedObject;
    }
  },
  watch: {
    // design-elements-related
    targetId: {
      immediate: true,
      handler(id) {
        if (!this.showingDesignPage) return;
        this.selectedObject = this.showingDesignPage[id];
      }
    }
  }
};
</script>

<style >
.project-properties .properties {
  height: calc(100% - 5rem - 14px) !important;
}
</style>