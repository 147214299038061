/**
 * Bubbles Group
 */
import * as _ from "lodash";
import { times } from "lodash";
import outerBorder from "../utils/outerPoints";
export default class ColorGridClass extends Phaser.GameObjects.Container {
  colors: number[] = [
    0xc0392b,
    0xe74c3c,
    0x9b59b6,
    0x8e44ad,
    0x2980b9,
    0x3498db,
    0x1abc9c,
    0x16a085,
    0x8fb502,
    0xb894c1,
    0xa6acaf,
    0x34495e,
    0xdeff1d,
    0xe86900,
    0xffb000,
    0xff00c2,
    0xff0086,
    0x3d2244
  ];
  constructor(scene: Phaser.Scene) {
    super(scene, scene.cameras.main.centerX, scene.cameras.main.centerY);
    this.setSize(
      this.scene.cameras.main.displayWidth * 0.8,
      this.scene.cameras.main.displayHeight * 0.6
    );
    this.setX(this.scene.cameras.main.centerX);
    this.setY(this.scene.cameras.main.centerY);
    this.setInteractive();
    this.scene.input.setDraggable(this);
    this.scene.add.existing(this);
    this.initialize(4, 4);
    this.type = "colorgrid";
  }

  initialize(row, col) {
    this.setData("row", row);
    this.setData("col", col);

    this.removeAll(true);

    let unitWidth = this.width / col;
    let unitHeight = this.height / row;
    let designTopLeftX = -this.width / 2 + unitWidth / 2;
    let designTopLeftY = -this.height / 2 + unitHeight / 2;

    for (let i = 0; i < row; i++) {
      for (let j = 0; j < col; j++) {
        const x = designTopLeftX + j * unitWidth + j * 2;
        const y = designTopLeftY + i * unitHeight + i * 2;

        const random = Number(Math.random() * this.colors.length)
          .toString()
          .split(".")[0];
        const rectangle = new Phaser.GameObjects.Rectangle(
          this.scene,
          x,
          y,
          unitWidth - 4,
          unitHeight - 4,
          this.colors[random]
        ).setDepth(300);
        this.add(rectangle);

        // Add GameObject to the scene.
        // this.scene.add.existing(rectangle);
      }
    }
  }
}
