<!--
 Extends @/components/design-elements-section
-->

<script>
import DesignElementsSection from "commonsfrontend/src/maker/vue-files/components/design-elements-section";
import elements from "@/types/design-elements/sounds/index.ts";
/* eslint-disable no-undef */
export default {
  extends: DesignElementsSection,
  data: function() {
    return {
      domClass: {
        height: "basis-1",
        overflow: "of-x"
      }
    };
  },
  computed: {
    /* Filter elements which are not hidden */
    elements: () =>
      Object.keys(elements).reduce((acc, name) => {
        const designObject = elements[name];
        if (!designObject.info.hidden) acc[name] = designObject;
        return acc;
      }, {})
  },
  methods: {
    async dragstartHandler(e, item) {
      /*
      const anim = gsap.timeline();
      if (item.tag === "ion-toolbar") {
        anim.fromTo(
          "ion-header",
          { background: "transparent" },
          {
            background: "#fff691",
            yoyo: 1,
            duration: 0.5,
            repeat: -1
          }
        );
      } else {
        anim.fromTo(
          "ion-content",
          {
            "--background": "white"
          },
          {
            "--background": "#fff691",
            duration: 0.5,
            yoyo: true,
            repeat: -1
          }
        );
      }

      this.$once("dragEndHandler", () => {
        anim
          .pause(anim.endTime())
          .clear()
          .kill();
      });
      */

      //console.log(item);
      e.dataTransfer.dropEffect = "copy";
      e.dataTransfer.effectAllowed = "all";
      e.dataTransfer.setData(
        "item",
        JSON.stringify({ ...item.structure, title: item.info.title })
      );
      e.dataTransfer.setData("newItem", true);
      e.dataTransfer.setData("type", "sound");
      if (item.type && item.type == "plugin")
        e.dataTransfer.setData("plugin", true);
    },
    onClick(item) {
      const audio = new Audio(item.structure.file);
      audio.play();
    }
  }
};
</script>

<style lang="scss" scoped>
.basis-1 {
  min-height: 97px !important;
}
.list-content.of-x {
  margin: 0;
  padding: 0;
  li {
    button {
      width: 4em;
      height: 4em;
      padding: 0 0.5em;
      img {
        padding: 0.25em 0 0 0;
        opacity: unset;
      }
      span {
        padding: 0;
        margin: 0;
      }
    }
  }
}
</style>
