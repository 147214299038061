/**
 * Bubbles Group
 */
import * as _ from "lodash";
import { times } from "lodash";
import outerBorder from "../utils/outerPoints";
import { AsyncImageLoader } from "./core/asyncImageLoader";
export default class RandomImagesClass extends Phaser.GameObjects.Container {
  questionBoxTexture: string;
  arrowTexture: string;
  showingIndex: number = 0;
  questionSet: string[] = [];
  _duration = 100;
  _interval;
  constructor(scene: Phaser.Scene) {
    super(scene, scene.cameras.main.centerX, scene.cameras.main.centerY);
    this.setSize(
      this.scene.cameras.main.displayWidth * 0.8,
      this.scene.cameras.main.displayHeight * 0.6
    );
    this.setX(this.scene.cameras.main.centerX);
    this.setY(this.scene.cameras.main.centerY);
    this.setInteractive();
    this.scene.input.setDraggable(this);
    this.scene.add.existing(this);
  }

  async load() {
    const uploads = [1, 2, 3, 4, 5, 6, 7, 8, 9, "10"].map(async i =>
      await new AsyncImageLoader(this.scene, {
        key: "shape" + i,
        src: window.location.origin + "/img/assets/shape" + i + ".png"
      }).start()
    );

    return Promise.all(uploads).then(() => {
      for (const i of [1, 2, 3, 4, 5, 6, 7, 8, 9, "10"])
        this.questionSet.push("shape" + i);
      this.initialize();
    })
  }

  get duration() {
    return this._duration;
  }

  set duration(ms) {
    if (this._interval) clearInterval(this._interval);

    this._interval = setInterval(() => {
      this.moveObject(
        this.showing(),
        this.scene.cameras.main.displayWidth * -1,
        () => {
          this.remove(this.showing(), true);
          this.showingIndex = Number(
            (Math.random() * (this.questionSet.length - 1)).toFixed(0)
          );
          this.newSlide();
        }
      );
    }, ms);
    this._duration = ms;
  }

  showing() {
    return this.list[0];
  }

  async initialize() {
    /*const leftArrow = this.scene.add
      .image((this.width / 2) * -1, 0, this.arrowTexture)
      .setScale(0.2)
      .toggleFlipX();
    leftArrow.setInteractive();*/
    // this.add(leftArrow);

    // this.scene.input.enableDebug(leftArrow);
    // this.scene.input.enableDebug(rightArrow);
    this.showingIndex = 1;
    this.newSlide();
    this.duration = 2000;
  }

  moveObject(ob, x, onComplete) {
    this.scene.tweens.add({
      targets: ob,
      x,
      ease: "Back",
      duration: 500,
      onComplete,
      onCompleteParams: []
    });
  }

  newSlide() {
    const question = this.scene.add
      .image(0, 0, this.questionSet[this.showingIndex])
      .setScale(0.5);
    this.add(question);
  }
}
