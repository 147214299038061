export default {
  alignment: [
    {
      label: "Alignment",
      options: [
        { text: "Top Center", value: 1 },
        { text: "Top Left", value: 2 },
        { text: "Top Right", value: 3 },
        { text: "Middle Center", value: 4 },
        { text: "Middle Left", value: 5 },
        { text: "Middle Right", value: 6 },
        { text: "Bottom Center", value: 7 },
        { text: "Bottom Left", value: 8 },
        { text: "Bottom Right", value: 9 }
      ],
      inputType: "select",
      get(gameObject: Phaser.GameObjects.TileSprite) {
        return undefined;
      },
      set(gameObject: Phaser.GameObjects.TileSprite, value) {
        // @ts-ignore
        const camera = gameObject.scene.cameras.main;
        if (value === 1) {
          gameObject.setX(camera.centerX + camera.scrollX);
          gameObject.setY(
            camera.centerY +
              camera.scrollY -
              (camera.height / 2 - gameObject.displayHeight / 2)
          );
        } else if (value === 2) {
          gameObject.setX(
            camera.centerX +
              camera.scrollX -
              (camera.width / 2 - gameObject.displayWidth / 2)
          );
          gameObject.setY(
            camera.centerY +
              camera.scrollY -
              (camera.height / 2 - gameObject.displayHeight / 2)
          );
        } else if (value == 3) {
          gameObject.setX(
            camera.centerX +
              camera.scrollX +
              (camera.width / 2 - gameObject.displayWidth / 2)
          );
          gameObject.setY(
            camera.centerY +
              camera.scrollY -
              (camera.height / 2 - gameObject.displayHeight / 2)
          );
        } else if (value === 4) {
          gameObject.setX(camera.centerX + camera.scrollX);
          gameObject.setY(camera.centerY + camera.scrollY);
        } else if (value === 5) {
          gameObject.setX(
            camera.centerX +
              camera.scrollX -
              (camera.width / 2 - gameObject.displayWidth / 2)
          );
          gameObject.setY(camera.centerY + camera.scrollY);
        } else if (value === 6) {
          gameObject.setX(
            camera.centerX +
              camera.scrollX +
              (camera.width / 2 - gameObject.displayWidth / 2)
          );
          gameObject.setY(camera.centerY + camera.scrollY);
        } else if (value === 7) {
          gameObject.setX(camera.centerX + camera.scrollX);
          gameObject.setY(
            camera.centerY +
              camera.scrollY +
              (camera.height / 2 - gameObject.displayHeight / 2)
          );
        } else if (value === 8) {
          gameObject.setX(
            camera.centerX +
              camera.scrollX -
              (camera.width / 2 - gameObject.displayWidth / 2)
          );
          gameObject.setY(
            camera.centerY +
              camera.scrollY +
              (camera.height / 2 - gameObject.displayHeight / 2)
          );
        } else if (value === 9) {
          gameObject.setX(
            camera.centerX +
              camera.scrollX +
              (camera.width / 2 - gameObject.displayWidth / 2)
          );
          gameObject.setY(
            camera.centerY +
              camera.scrollY +
              (camera.height / 2 - gameObject.displayHeight / 2)
          );
        }
      }
    }
  ],
  position: [
    {
      label: "X Position",
      inputType: "number",
      step: "0.5",
      importKey: "x",
      get(gameObject: Phaser.GameObjects.TileSprite) {
        return Number.parseFloat("" + gameObject.x).toFixed(1);
      },
      set(gameObject: Phaser.GameObjects.TileSprite, value) {
        gameObject.setX(value);
      }
    },
    {
      label: "Y Position",
      inputType: "number",
      step: "0.5",
      importKey: "y",
      get(gameObject: Phaser.GameObjects.TileSprite) {
        return Number.parseFloat("" + gameObject.y).toFixed(1);
      },
      set(gameObject: Phaser.GameObjects.TileSprite, value) {
        gameObject.setY(value);
      }
    },
    {
      label: "Depth",
      inputType: "number",
      step: "10",
      min: 0,
      max: 10000,
      importKey: "depth",
      get(gameObject: Phaser.GameObjects.TileSprite) {
        return Number.parseFloat("" + gameObject.depth).toFixed(0);
      },
      set(gameObject: Phaser.GameObjects.TileSprite, value) {
        gameObject.setDepth(value);
      }
    }
  ],
  size: [
    {
      label: "Width",
      inputType: "number",
      step: "1",
      importKey: "displayWidth",
      get: (gameObject: Phaser.GameObjects.TileSprite) =>
        Number.parseFloat("" + gameObject.displayWidth).toFixed(1),
      set: (gameObject: Phaser.GameObjects.TileSprite, value: number) => {
        gameObject.setDisplaySize(value, gameObject.displayHeight);
      }
    },
    {
      label: "Height",
      inputType: "number",
      step: "1",
      importKey: "displayHeight",
      get: (gameObject: Phaser.GameObjects.TileSprite) =>
        Number.parseFloat("" + gameObject.displayHeight).toFixed(1),
      set: (gameObject: Phaser.GameObjects.TileSprite, value: number) => {
        gameObject.setDisplaySize(gameObject.displayWidth, value);
      }
    },
    {
      label: "Scale",
      inputType: "number",
      step: "0.001",
      importKey: "scale",
      get(gameObject: Phaser.GameObjects.TileSprite) {
        return Number.parseFloat("" + gameObject.scale).toFixed(3);
      },
      set(gameObject: Phaser.GameObjects.TileSprite, value: number) {
        gameObject.setScale(Number(value));
        if (gameObject.type === "background") {
        }
      }
    }
  ],
  filter: [
    {
      label: "Alpha",
      inputType: "number",
      step: "0.01",
      max: 1,
      min: 0,
      importKey: "alpha",
      get(gameObject: Phaser.GameObjects.TileSprite) {
        return Number.parseFloat("" + gameObject.alpha).toFixed(1);
      },
      set(gameObject: Phaser.GameObjects.TileSprite, value) {
        gameObject.setAlpha(value);
      }
    },
    {
      label: "Angle",
      inputType: "number",
      step: "1",
      max: 180,
      min: -180,
      importKey: "angle",
      get(gameObject: Phaser.GameObjects.TileSprite) {
        return Number.parseFloat("" + gameObject.angle).toFixed(1);
      },
      set(gameObject: Phaser.GameObjects.TileSprite, value) {
        gameObject.setAngle(value);
      }
    },
    {
      label: "Flip",
      inputType: "buttons",
      options: [
        { text: "X", value: "x" },
        { text: "Y", value: "y" },
        { text: "Both", value: "both" }
      ],
      localModel: true,
      importKey: ["flipX", "flipY"],
      get(gameObject: Phaser.GameObjects.TileSprite) {
        return undefined;
      },
      set(gameObject: Phaser.GameObjects.TileSprite, value) {
        if (value === "x") gameObject.toggleFlipX();
        else if (value === "y") gameObject.toggleFlipY();
        else if (value === "both") {
          gameObject.toggleFlipX();
          gameObject.toggleFlipY();
        }
      }
    }
  ],
  parallax: [
    {
      label: "Tile X",
      inputType: "number",
      importKey: "tileX",
      get(gameObject: Phaser.GameObjects.TileSprite) {
        return gameObject.getData("tileX");
      },
      set(gameObject: Phaser.GameObjects.TileSprite, value) {
        gameObject.setData("tileX", Number(value));
      }
    },
    {
      label: "Tile Y",
      inputType: "number",
      importKey: "tileY",
      get(gameObject: Phaser.GameObjects.TileSprite) {
        return gameObject.getData("tileY");
      },
      set(gameObject: Phaser.GameObjects.TileSprite, value) {
        gameObject.setData("tileY", Number(value));
      }
    },
    {
      label: "Fps",
      inputType: "number",
      importKey: "fps",
      get(gameObject: Phaser.GameObjects.TileSprite) {
        return gameObject.getData("fps") / 10;
      },
      set(gameObject: Phaser.GameObjects.TileSprite, value) {
        gameObject.setData("fps", Number(value) * 10);
      }
    }
  ],
  animations(...anims) {
    return {
      label: "Animations",
      inputType: "buttons",
      options: anims.map(key => ({ text: key, value: key })),
      localModel: false,
      get(gameObject: Phaser.GameObjects.TileSprite) {
        return undefined;
      },
      set(gameObject: Phaser.GameObjects.Sprite, value) {
        //@ts-ignore
        gameObject.animations
          .find(anim => anim.key === value)
          .sprite.play(value);
      }
    };
  },
  content: [
    {
      label: "Content",
      inputType: "text",
      importKey: "text",
      get(gameObject: Phaser.GameObjects.Container) {
        // @ts-ignore
        return gameObject.content();
      },
      set(gameObject: Phaser.GameObjects.Container, value) {
        // @ts-ignore
        gameObject.setContent(value);
      }
    },
    {
      label: "Background Color",
      inputType: "color",
      importKey: settings => settings.style["backgroundColor"],
      get(gameObject: Phaser.GameObjects.Container) {
        return undefined;
      },
      set(gameObject: Phaser.GameObjects.Container, value) {
        // @ts-ignore
        gameObject.list[0].setBackgroundColor(value);
      }
    },
    {
      label: "Content Color",
      inputType: "color",
      importKey: settings => settings.style["color"],
      get(gameObject: Phaser.GameObjects.Container) {
        return undefined;
      },
      set(gameObject: Phaser.GameObjects.Container, value) {
        // @ts-ignore
        gameObject.list[0].setColor(value).setShadowColor(value);
      }
    }
  ]
};
