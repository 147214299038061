/* eslint-disable */
import * as Phaser from "phaser";
import { InfoBox } from "./classes/core/infoBox";
import { AsyncImageLoader } from "./classes/core/asyncImageLoader";
import { Tooltip } from "./classes/core/tooltip";
import { Timer } from "./classes/timer";
import wait from "./utils/waitPromise";
import outerBorder from "./utils/outerPoints";
import ColorGrid from "./classes/colorgrid";
import RandomImages from "./classes/randomimages";
import ImagePuzzle from "./classes/imagepuzzle";
import ImportDesignSettingValues from "@/types/screen/edit-mode/importDesignSettingValues.ts";
const stringHash = require("string-hash");

interface IdesignElement {
  values: any;
  type: string;
  orientation?: string;
  name: string;
  src: string;
  width?: number;
  height?: number;
  design?: any;
  anims?: any;
}

enum DesignMode {
  FREE_DRAG,
  CENTER
}
let designInstanceIndexCounter = 1;
export class Design extends Phaser.Scene {
  private _mode: DesignMode = 1;

  static DESIGN_MODE_FREE_DRAG_ON = Symbol("DESIGN_MODE_FREE_DRAG_ON");
  static DESIGN_MODE_FREE_DRAG_OFF = Symbol("DESIGN_MODE_FREE_DRAG_OFF");
  static SHOW_MAIN_MENU = Symbol("SHOW_MAIN_MENU");

  constructor() {
    super({ key: "Design" });
    //@ts-ignore
    window.gameScene = this;
  }

  create() {
    this.cameras.main.setBackgroundColor(0x000);
    this._initMainMenu();
    this._dragHint();

    this.input.on("drag", function(pointer, gameObject, dragX, dragY) {
      gameObject.x = dragX;
      gameObject.y = dragY;
    });
  }

  update() {}

  public async addDesignElement(designElement: any, $vue: any, type: string) {
    let designInstance;
    if (type === "background") {
      designInstance = await this.addBackground(designElement, $vue);
    } else if (type === "asset") {
      designInstance = await this.addAsset(designElement, $vue);
    } else if (type === "container") {
      designInstance = await this.addAssetContainer(designElement, $vue);
    } else if (type === "imagepuzzle") {
      designInstance = await this.addImagePuzzle(designElement, $vue);
    } else if (type === "randomimages") {
      designInstance = await this.addRandomImages(designElement, $vue);
    } else if (type === "colorgrid") {
      designInstance = await this.addColorGrid(designElement, $vue);
    } else if (type === "timer") {
      designInstance = await this.addTimer(designElement, $vue);
    } else if (type === "text") {
      designInstance = await this.addText(designElement, $vue);
    } else if (type === "button") {
      designInstance = await this.addButton(designElement, $vue);
    } else if (type === "sound") {
    } else if (type === "plugin") {
    }
    //console.log("instance:",designInstance)
    // set name
    designInstance.name = designElement.name;
    // set type
    designInstance.setData("designElement", {
      ...designElement,
      type
    });
    if (
      designElement &&
      designElement.settings &&
      designElement.settings.lastSession === true
    ) {
      ImportDesignSettingValues(designInstance, designElement.settings);
    }
    designInstance.setData("index", designInstanceIndexCounter++);
    $vue.addDesignElement({
      k: designElement.name,
      o: designInstance,
      designElement
    });
    return designElement.name;
  }

  public async addAssetContainer(designElement: IdesignElement, $vue: any) {
    const assetContainer = new Phaser.GameObjects.Container(
      this,
      this.cameras.main.centerX + this.cameras.main.scrollX,
      this.cameras.main.centerY + this.cameras.main.scrollY
    );

    assetContainer.setData("imgSrc", designElement.src);
    // @ts-ignore
    assetContainer.animations = [];
    const { list } = designElement.design;

    assetContainer.setData("list", list);

    /*  assetContainer.setSize(
      designElement.design.width,
      designElement.design.height
    ); */

    // Map all asset list to Image Loader Promise
    const imageKeys = await Promise.all(
      list.map(asset => {
        return new AsyncImageLoader(this, {
          key: asset.name,
          src: asset.src
        }).start();
      })
    );
    const points = [];
    for (let i = 0; i < imageKeys.length; i++) {
      const asset = list[i];
      asset.key = imageKeys[i];
      points.push([asset.position[0], asset.position[1]]);
      if (asset.type === "image") {
        let image = new Phaser.GameObjects.Image(
          this,
          asset.position[0],
          asset.position[1],
          asset.key
        ).setScale(asset.scale);

        assetContainer.add(image);

        this._addAllBorders(points, image);
      } else if (asset.type === "sprite") {
        // Sprite adding non-exclusive because it has animations, animations render in scene.
        const sprite = this.add
          .sprite(asset.position[0], asset.position[1], asset.key)
          .setVisible(false)
          .setScale(asset.scale);

        for (let anim in asset.anims) {
          await Promise.all(
            asset.anims[anim].map(key =>
              new AsyncImageLoader(this, {
                key: String(stringHash(key)),
                src: key
              }).start()
            )
          ).then(frames => {
            this.anims.create({
              key: anim,
              //@ts-ignore
              frames: frames.map(key => ({
                key,
                frame: null
              })),
              yoyo: true,
              repeat: 0
            });
            // @ts-ignore
            assetContainer.animations.push({
              key: anim,
              sprite
            });
          });
          assetContainer.add(sprite.setVisible(true));

          this._addAllBorders(points, sprite);
        }
      }
    }

    const shape = new Phaser.Geom.Polygon(
      outerBorder(points).map(
        point => new Phaser.Geom.Point(point[0], point[1])
      )
    );
    assetContainer.setInteractive(shape, Phaser.Geom.Polygon.Contains);

    /* === HIT AREA DEBUG ===
    var graphics = new Phaser.GameObjects.Graphics(this, {
      x: 0,
      y: 0
    });
    graphics.lineStyle(1, 0xcd00cd);
    graphics.beginPath();
    graphics.moveTo(shape.points[0].x, shape.points[0].y);
    for (var i = 1; i < shape.points.length; i++) {
      graphics.lineTo(shape.points[i].x, shape.points[i].y);
    }
    graphics.closePath();
    graphics.strokePath();
    assetContainer.add(graphics); */

    assetContainer.input.cursor = "pointer";
    this.input.setDraggable(assetContainer);

    assetContainer.on("pointerup", (pointer, element) => {
      // PROPERTIES IMPLEMENT EDILECEK
      $vue.selectElement({
        type: "container",
        element: assetContainer,
        settings: [
          //"content",
          //"style",
          "position",
          "alignment",
          "size",
          "filter",
          //"tileAnimaton",
          "animations",
          "flip",
          "tint"
        ]
      });
    });
    this.add.existing(assetContainer);
    const tooltip = new Tooltip(
      this,
      assetContainer,
      designElement.name
    ).bindToVue($vue, "STOP", "DESIGN");
    return assetContainer;
  }

  public async addAsset(designElement: IdesignElement, $vue: any) {
    const imageKey: string = await new AsyncImageLoader(this, {
      key: designElement.name,
      src: designElement.src
    }).start();

    let image = this.add.sprite(
      this.cameras.main.centerX + this.cameras.main.scrollX,
      this.cameras.main.centerY + this.cameras.main.scrollY,
      imageKey
    );
    image.setDisplaySize(designElement.width, designElement.height);

    image.setInteractive();

    image.setData("imgSrc", designElement.src);

    if (designElement.values) image.setData(designElement.values);

    this.input.setDraggable(image);

    image.setData("anims", designElement.anims);

    // @ts-ignore
    image.animations = [];
    for (let anim in designElement.anims) {
      await Promise.all(
        designElement.anims[anim].map(key =>
          new AsyncImageLoader(this, {
            key: String(stringHash(key)),
            src: key
          }).start()
        )
      ).then(frames => {
        this.anims.create({
          key: anim,
          //@ts-ignore
          frames: frames.map(key => ({
            key,
            frame: null
          })),
          yoyo: true,
          repeat: 0
        });
        // @ts-ignore
        image.animations.push({
          key: anim,
          sprite: image
        });
      });
    }

    image.on("pointerup", (pointer, element) => {
      // PROPERTIES IMPLEMENT EDILECEK
      $vue.selectElement({
        type: "asset",
        element: image,
        settings: [
          //"content",
          //"style",
          "position",
          "alignment",
          "size",
          "filter",
          //"tileAnimaton",
          "animations",
          "flip",
          "tint"
        ]
      });
    });

    const tooltip = new Tooltip(this, image, imageKey).bindToVue(
      $vue,
      "STOP",
      "DESIGN"
    );
    return image;
  }

  public async addBackground(designElement: IdesignElement, $vue: any) {
    const imageKey: string = await new AsyncImageLoader(this, {
      key: designElement.name,
      src: designElement.src
    }).start();

    let imageLoad = this.add.image(0, 0, imageKey).setVisible(false);

    let image = this.add.tileSprite(
      this.cameras.main.centerX + this.cameras.main.scrollX,
      this.cameras.main.centerY + this.cameras.main.scrollY,
      imageLoad.width,
      imageLoad.height,
      imageKey
    );
    imageLoad.destroy();
    image.setDisplaySize(this.cameras.main.width, this.cameras.main.height);

    image.setInteractive();

    image.setData("imgSrc", designElement.src);

    this.input.setDraggable(image);

    image.on("pointerup", (pointer, element) => {
      // PROPERTIES IMPLEMENT EDILECEK
      $vue.selectElement({
        type: "background",
        element: image,
        settings: [
          //"content",
          //"style",
          "position",
          "alignment",
          "size",
          "filter",
          "tileAnimaton",
          //"animations",
          "flip",
          "tint"
        ]
      });
    });

    image.setData("tileX", 0);
    image.setData("tileY", 0);
    image.setData("fps", 0);

    let tileInterval;

    image.data.events.on("changedata", () => {
      clearInterval(tileInterval);
      if (image.getData("fps"))
        tileInterval = setInterval(() => {
          image.setTilePosition(
            image.tilePositionX + image.getData("tileX"),
            image.tilePositionY + image.getData("tileY")
          );
        }, 1000 / image.getData("fps"));
    });

    //@ts-ignore
    image.src = designElement.src;
    const tooltip = new Tooltip(this, image, imageKey).bindToVue(
      $vue,
      "STOP",
      "DESIGN"
    );
    return image;
  }

  public async addButton(designElement: IdesignElement, $vue: any) {
    const buttonContainer = this.add.container(
      this.cameras.main.centerX + this.cameras.main.scrollX,
      this.cameras.main.centerY + this.cameras.main.scrollY
    );
    const points = [];
    const buttonText = new Phaser.GameObjects.Text(this, 0, 0, "Button", {
      fontFamily: 'Georgia, "Goudy Bookletter 1911", Times, serif',
      fontSize: "16px",
      padding: { left: 10, right: 10, top: 7, bottom: 7 },
      backgroundColor: "#000",
      color: "#fff",
      strokeThickness: 1
    }).setWordWrapWidth(this.cameras.main.width);
    buttonContainer
      .setSize(buttonText.width, buttonText.height)
      .setDisplaySize(buttonText.width, buttonText.height);

    this._addAllBorders(points, buttonText);

    buttonContainer.setInteractive();

    this.input.setDraggable(buttonContainer);
    /*
        const shape = new Phaser.Geom.Polygon(
      outerBorder(points).map(
        point => new Phaser.Geom.Point(point[0], point[1])
      )
    );

    buttonContainer.setInteractive(shape, Phaser.Geom.Polygon.Contains);
    var graphics = new Phaser.GameObjects.Graphics(this, {
      x: 0,
      y: 0
    });
    graphics.lineStyle(1, 0xcd00cd);
    graphics.beginPath();
    graphics.moveTo(shape.points[0].x, shape.points[0].y);
    for (var i = 1; i < shape.points.length; i++) {
      graphics.lineTo(shape.points[i].x, shape.points[i].y);
    }
    graphics.closePath();
    graphics.strokePath();
    buttonContainer.add(graphics);*/

    buttonContainer.add(buttonText);
    buttonText.setPosition(-buttonText.width / 2, -buttonText.height / 2);
    buttonContainer.on("pointerup", (pointer, element) => {
      // PROPERTIES IMPLEMENT EDILECEK
      $vue.selectElement({
        type: "button",
        element: buttonContainer,
        settings: [
          "content",
          "style",
          "position",
          "alignment",
          "size",
          "filter",
          //"tileAnimaton",
          //"animations",
          //"flip",
          "tint"
        ]
      });
    });
    /*
    Hover Effect
    buttonContainer.on("pointerover", () => {
      buttonText.setBackgroundColor("#fff");
      buttonText.setColor("#000");
    });

    buttonContainer.on("pointerout", () => {
      buttonText.setBackgroundColor("#000");
      buttonText.setColor("#fff");
    });
    */
    // this.input.enableDebug(buttonContainer);
    // @ts-ignore
    buttonContainer.content = () => buttonText.text;
    // @ts-ignore
    buttonContainer.setContent = content => {
      // this.input.removeDebug(buttonContainer);
      buttonText.setText(content);
      buttonText.setPosition(-buttonText.width / 2, -buttonText.height / 2);
      buttonContainer.setSize(buttonText.width, buttonText.height);
      buttonText.setAlign;
      buttonContainer.input.hitArea.setTo(
        buttonContainer.input.hitArea.x,
        buttonContainer.input.hitArea.y,
        buttonText.width,
        buttonText.height
      );
      // this.input.enableDebug(buttonContainer);
    };

    const tooltip = new Tooltip(
      this,
      buttonContainer,
      designElement.name
    ).bindToVue($vue, "STOP", "DESIGN");
    return buttonContainer;
  }

  public async addText(designElement: IdesignElement, $vue: any) {
    const buttonContainer = this.add.container(
      this.cameras.main.centerX + this.cameras.main.scrollX,
      this.cameras.main.centerY + this.cameras.main.scrollY
    );
    const points = [];
    const buttonText = new Phaser.GameObjects.Text(this, 0, 0, "Text", {
      fontSize: "16px",
      padding: { left: 10, right: 10, top: 7, bottom: 7 },
      color: "#000",
      strokeThickness: 1
    }).setWordWrapWidth(this.cameras.main.width);
    buttonContainer
      .setSize(buttonText.width, buttonText.height)
      .setDisplaySize(buttonText.width, buttonText.height);

    this._addAllBorders(points, buttonText);

    buttonContainer.setInteractive();

    this.input.setDraggable(buttonContainer);

    buttonContainer.add(buttonText);
    buttonText.setPosition(-buttonText.width / 2, -buttonText.height / 2);
    buttonContainer.on("pointerup", (pointer, element) => {
      // PROPERTIES IMPLEMENT EDILECEK
      $vue.selectElement({
        type: "button",
        element: buttonContainer,
        settings: [
          "content",
          "style",
          "position",
          "alignment",
          "size",
          "filter",
          //"tileAnimaton",
          //"animations",
          //"flip",
          "tint"
        ]
      });
    });

    // this.input.enableDebug(buttonContainer);
    // @ts-ignore
    buttonContainer.content = () => buttonText.text;
    // @ts-ignore
    buttonContainer.setContent = content => {
      // this.input.removeDebug(buttonContainer);
      buttonText.setText(content);
      buttonText.setPosition(-buttonText.width / 2, -buttonText.height / 2);
      buttonContainer.setSize(buttonText.width, buttonText.height);
      buttonText.setAlign;
      buttonContainer.input.hitArea.setTo(
        buttonContainer.input.hitArea.x,
        buttonContainer.input.hitArea.y,
        buttonText.width,
        buttonText.height
      );
      // this.input.enableDebug(buttonContainer);
    };

    const tooltip = new Tooltip(
      this,
      buttonContainer,
      designElement.name
    ).bindToVue($vue, "STOP", "DESIGN");

    return buttonContainer;
  }

  public async addTimer(designElement: IdesignElement, $vue: any) {
    const timer = new Timer(
      this,
      this.cameras.main.centerX + this.cameras.main.scrollX,
      this.cameras.main.centerY + this.cameras.main.scrollY
    );

    timer.setData("imgSrc", require("@/assets/img/icons/timer.png"));

    timer.on("pointerup", (pointer, element) => {
      // PROPERTIES IMPLEMENT EDILECEK
      $vue.selectElement({
        type: "Timer",
        element: timer,
        settings: [
          //"content",
          "style",
          "position",
          "alignment"
          //"size"
          //"filter",
          //"tileAnimaton",
          //"animations",
          //"flip",
          //"tint"
        ]
      });
    });

    const tooltip = new Tooltip(this, timer, designElement.name).bindToVue(
      $vue,
      "STOP",
      "DESIGN"
    );
    return timer;
  }

  public async addImagePuzzle(designElement: IdesignElement, $vue: any) {
    const imagepuzzle = new ImagePuzzle(this);

    const imagepuzzleLoad = imagepuzzle.load();

    imagepuzzle.on("pointerup", (pointer, element) => {
      // PROPERTIES IMPLEMENT EDILECEK
      $vue.selectElement({
        type: "imagepuzzle",
        element: imagepuzzle,
        settings: []
      });
    });

    const tooltip = new Tooltip(
      this,
      imagepuzzle,
      designElement.name
    ).bindToVue($vue, "STOP", "DESIGN");
    await imagepuzzleLoad;
    return imagepuzzle;
  }

  public async addColorGrid(designElement: IdesignElement, $vue: any) {
    const colorgrid = new ColorGrid(this);

    colorgrid.on("pointerup", (pointer, element) => {
      // PROPERTIES IMPLEMENT EDILECEK
      $vue.selectElement({
        type: "colorgrid",
        element: colorgrid,
        settings: []
      });
    });

    const tooltip = new Tooltip(this, colorgrid, designElement.name).bindToVue(
      $vue,
      "STOP",
      "DESIGN"
    );

    // timer.setData("imgSrc", require("@/assets/img/icons/timer.png"));
    /*
    colorgrid.on("pointerup", (pointer, element) => {
      // PROPERTIES IMPLEMENT EDILECEK
      $vue.selectElement({
        type: "Timer",
        element: timer,
        settings: [
          //"content",
          "style",
          "position",
          "alignment"
          //"size"
          //"filter",
          //"tileAnimaton",
          //"animations",
          //"flip",
          //"tint"
        ]
      });
    });*/
    /*
    timer.name = designElement.name;
    $vue.addDesignElement({ k: designElement.name, o: colorgrid });
    const tooltip = new Tooltip(this, colorgrid, designElement.name).bindToVue(
      $vue,
      "STOP",
      "DESIGN"
    );*/

    return colorgrid;
  }

  public async addRandomImages(designElement: IdesignElement, $vue: any) {
    const randomImages = new RandomImages(this);
    const randomImagesLoad = randomImages.load();
    randomImages.on("pointerup", (pointer, element) => {
      // PROPERTIES IMPLEMENT EDILECEK
      $vue.selectElement({
        type: "randomImages",
        element: randomImages,
        settings: []
      });
    });
    const tooltip = new Tooltip(
      this,
      randomImages,
      designElement.name
    ).bindToVue($vue, "STOP", "DESIGN");
    await randomImagesLoad;
    return randomImages;
  }

  private _dragHint() {
    this.add.text(
      this.cameras.main.centerX - 60,
      this.cameras.main.centerY,
      `    Drag 
A Background`
    );
  }

  private _initMainMenu() {
    this._initFreeCamera();
    let lastPointer;
    let menuContainer = this.add.container(0, 0).setDepth(900);

    const frame = this.add.rectangle(0, 0, 200, 300, 0xffffff, 1);

    const mainMenu = InfoBox.alignCenterByObject(
      frame,
      this.add.text(0, 0, "Main Menu", {
        color: "#000",
        fontSize: "15"
      })
    );

    const menuItemStyle = {
      color: "#000",
      padding: {
        left: 5,
        right: 5
      },
      backgroundColor: "#fff000"
    };

    // MENUS

    const [
      freeCameraMenu,
      centeredCamera,
      switchGrid,
      clickedPointMenu,
      closeMenu
    ] = [
      this.add
        .text(0, 0, "Show Free Camera", menuItemStyle)
        .setInteractive()
        .setData("switch", "off"),
      this.add.text(0, 0, "Centered Camera", menuItemStyle).setInteractive(),
      this.add
        .text(0, 0, "Show grids", menuItemStyle)
        .setInteractive()
        .setData("switch", "off"),
      this.add.text(0, 0, "Get Position", menuItemStyle).setInteractive(),
      this.add.text(0, 0, "Close", menuItemStyle).setInteractive()
    ].map((button, index) => {
      return InfoBox.alignCenterByObject(frame, button, index * 30 + 40);
    });

    const grids = this.add
      .grid(
        this.cameras.main.centerX,
        this.cameras.main.centerY,
        3000,
        3000,
        this.cameras.main.width / 10,
        this.cameras.main.width / 10,
        0xffffff,
        0.1,
        0x0,
        0.5
      )
      .setDepth(800)
      .setVisible(false);

    this.events.on(Design.DESIGN_MODE_FREE_DRAG_OFF, () => {
      freeCameraMenu.setData("switch", "off");
      freeCameraMenu.setText("Show Free Camera");
    });

    this.events.on(Design.DESIGN_MODE_FREE_DRAG_ON, () => {
      freeCameraMenu.setData("switch", "on");
      freeCameraMenu.setText("Hide Free Camera");
    });

    // MENU FUNCTIONALITY
    this.input.on("gameobjectdown", (pointer, o: any) => {
      if (o === freeCameraMenu) {
        const state = freeCameraMenu.getData("switch");
        if (state === "off") {
          this.events.emit(Design.DESIGN_MODE_FREE_DRAG_ON);
        } else {
          this.events.emit(Design.DESIGN_MODE_FREE_DRAG_OFF);
        }
        menuContainer.setVisible(false);
      } else if (o === centeredCamera) {
        this.cameras.main.setScroll(0, 0);
        this.events.emit(Design.DESIGN_MODE_FREE_DRAG_OFF); // Close free camera
        menuContainer.setVisible(false);
      } else if (o === closeMenu) {
        menuContainer.setVisible(false);
      } else if (o === switchGrid) {
        const state = switchGrid.getData("switch");
        if (state === "off") {
          grids.setVisible(true);
          switchGrid.setText("Hide grids");
          switchGrid.setData("switch", "on");
        } else {
          grids.setVisible(false);
          switchGrid.setText("Show grids");
          switchGrid.setData("switch", "off");
        }
        menuContainer.setVisible(false);
      } else if (o === clickedPointMenu) {
        menuContainer.setVisible(false);
        const pX = Number.parseFloat(lastPointer.x).toFixed(2);
        const pY = Number.parseFloat(lastPointer.y).toFixed(2);
        const gX = Number.parseFloat(lastPointer.x + 20).toFixed(2);
        const gY = Number.parseFloat(lastPointer.y + 20).toFixed(2);
        const infoBox = new InfoBox(
          this,
          this.cameras.main.centerX + this.cameras.main.scrollX,
          this.cameras.main.centerY + this.cameras.main.scrollY,
          {
            title: "Position",
            content: `
Pointer position
  x = ${pX}
  y = ${pY}

Grid Position  
  x = ${gX}
  y = ${gY}
`
          }
        ).setDepth(900);

        // Need later
        // infoBox.on("destroy", () => { });
      }
    });

    menuContainer
      .add([
        frame,
        mainMenu,
        freeCameraMenu,
        centeredCamera,
        switchGrid,
        clickedPointMenu,
        closeMenu
      ])
      .setVisible(false)
      .setDepth(100);

    this.events.on(Design.SHOW_MAIN_MENU, () => {
      this.events.emit(InfoBox.CLOSE_ALL_INFO_BOX);
      const centerX = this.cameras.main.scrollX + 135;
      const centerY = this.cameras.main.scrollY + 300;
      menuContainer.setX(centerX);
      menuContainer.setY(centerY);
      menuContainer.setVisible(true).setDepth(900);
    });

    this.input.on("pointerdown", pointer => {
      if (pointer.rightButtonDown() && pointer.getDuration() > 100) {
        this.events.emit(Design.SHOW_MAIN_MENU);
        lastPointer = {
          x: pointer.x,
          y: pointer.y,
          downX: pointer.x,
          downY: pointer.y
        };
      }
    });
  }

  private _initFreeCamera() {
    const freeCameraContainer = this.add.container(0, 0);
    let direction; // exact direction
    const arrowStyle = {
      fontSize: "8vh",
      color: "rgba(0,0,0,0.7)",
      stroke: "#ffffff",
      strokeThickness: 5
    };

    const leftArrow = this.add
      .text(-135, -50, "🠨", arrowStyle)
      .setInteractive();
    const rightArrow = this.add.text(70, -50, "🠪", arrowStyle).setInteractive();
    const topArrow = this.add.text(-20, -280, "🠩", arrowStyle).setInteractive();
    const bottomArrow = this.add
      .text(-20, 200, "🠫", arrowStyle)
      .setInteractive();

    leftArrow.on("pointerover", () => {
      direction = "left";
    });

    leftArrow.on("pointerout", () => {
      direction = null;
    });

    rightArrow.on("pointerover", () => {
      direction = "right";
    });

    rightArrow.on("pointerout", () => {
      direction = null;
    });

    topArrow.on("pointerover", () => {
      direction = "top";
    });

    topArrow.on("pointerout", () => {
      direction = null;
    });

    bottomArrow.on("pointerover", () => {
      direction = "bottom";
    });

    bottomArrow.on("pointerout", () => {
      direction = null;
    });

    // ARROW FUNCTIONALITIES
    this.input.on("gameobjectover", async (p, o: any) => {
      if (direction === null || direction === undefined) return;
      const scrollX = this.cameras.main.scrollX;
      const scrollY = this.cameras.main.scrollY;
      if (o === leftArrow) {
        this.cameras.main.setScroll(scrollX - 5, scrollY);
      } else if (o === rightArrow) {
        this.cameras.main.setScroll(scrollX + 5, scrollY);
      } else if (o === topArrow) {
        this.cameras.main.setScroll(scrollX, scrollY - 5);
      } else if (o === bottomArrow) {
        this.cameras.main.setScroll(scrollX, scrollY + 5);
      } else {
        return;
      }

      // Update container position
      const centerX = this.cameras.main.scrollX + this.cameras.main.centerX;
      const centerY = this.cameras.main.scrollY + this.cameras.main.centerY;
      freeCameraContainer.setX(centerX).setY(centerY);

      // Call again
      await wait(50);
      this.input.emit("gameobjectover", p, o);
    });

    freeCameraContainer
      .add([leftArrow, rightArrow, topArrow, bottomArrow])
      .setDepth(20000)
      .setVisible(false);

    this.events.on(Design.DESIGN_MODE_FREE_DRAG_ON, () => {
      const centerX = this.cameras.main.scrollX + this.cameras.main.centerX;
      const centerY = this.cameras.main.scrollY + this.cameras.main.centerY;
      freeCameraContainer
        .setX(centerX)
        .setY(centerY)
        .setVisible(true);
    });

    this.events.on(Design.DESIGN_MODE_FREE_DRAG_OFF, () => {
      const centerX = this.cameras.main.scrollX + this.cameras.main.centerX;
      const centerY = this.cameras.main.scrollY + this.cameras.main.centerY;
      freeCameraContainer
        .setX(centerX)
        .setY(centerY)
        .setVisible(false);
    });
  }

  private _addAllBorders(points, sprite) {
    points.push([sprite.getTopLeft().x, sprite.getTopLeft().y]);
    points.push([sprite.getTopRight().x, sprite.getTopRight().y]);
    points.push([sprite.getLeftCenter().x, sprite.getLeftCenter().y]);
    points.push([sprite.getRightCenter().x, sprite.getRightCenter().y]);
    points.push([sprite.getBottomLeft().x, sprite.getBottomLeft().y]);
    points.push([sprite.getBottomRight().x, sprite.getBottomRight().y]);
  }
}
