<!-- 
 Extends @/components/design-elements-section
-->

<script>
import DesignElementsSection from "commonsfrontend/src/maker/vue-files/components/design-elements-section";
import elements from "@/types/design-elements/assets/index.ts";
/* eslint-disable no-undef */
export default {
  extends: DesignElementsSection,
  data: function() {
    return {
      domClass: {
        height: "basis-rest",
        overflow: "of-y"
      }
    };
  },
  computed: {
    /* Filter elements which are not hidden */
    elements: () =>
      Object.keys(elements).reduce((acc, name) => {
        const designObject = elements[name];
        if (!designObject.info.hidden) acc[name] = designObject;
        return acc;
      }, {})
  },
  methods: {
    async dragstartHandler(e, item) {
      e.dataTransfer.dropEffect = "copy";
      e.dataTransfer.effectAllowed = "all";
      e.dataTransfer.setData("item", JSON.stringify(item.structure));
      e.dataTransfer.setData("type", "assets");
      e.dataTransfer.setData("newItem", true);
      //console.log("dragged", item);
    }
  }
};
</script>
<style lang="scss" scoped>
.list-content.of-y {
  margin: 0;
  padding: 0;
  li {
    width: auto;
    button {
      padding: 0 0.5em;
      img {
        width: calc(((100vw * 4.9 / 12) / 10) - 0.7em) !important;
        height: auto;
        padding: 0.25em 0 0 0;
        opacity: unset;
      }
      span {
        padding: 0;
        margin: 0;
      }
    }
  }
}
</style>