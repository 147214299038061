import DesignElementList from "@/types/design-elements/index"

export default function (gameObjectInstance: any, settings: any) {
    // find the designElement prototype to get setting functions.
    const designElement = DesignElementList.find(designElement => {
        if (!designElement) return;
        if (!designElement.structure) {
            //console.log("structre yok: ",designElement)
            return false;
        }
        if(designElement.structure.design.type === settings.designElement.design.type &&
            designElement.structure.name === settings.designElement.exactName
        ) {
            return true;
        }
    })
    if (!designElement) return;
    
    // set each setting value
    designElement.settings.forEach(designSetting => {
        if (!designSetting.importKey) return;
        let importKeys = designSetting.importKey;
        
        // convert to array for merge multiple and single importKeys
        if(!Array.isArray(importKeys)) {
            importKeys = [importKeys]
        }
        
        // set
        importKeys.forEach(importKey => {
            let value;
            if (typeof importKey === "string") {
                value = settings[importKey];
            } else if (typeof importKey === "function")
                value = importKey(settings);
            if (value === undefined) return;
            designSetting.set(gameObjectInstance, value)
        });
        
    });
}