/**
 * Load images asynchronous
 */
export class AsyncImageLoader extends Phaser.Loader.LoaderPlugin {
  private _key;

  /**
   *
   * @param scene The Scene to which this Game Object belongs. A Game Object can only belong to one Scene at a time.
   * @param obj {key,src} texture name and texture url.
   */
  constructor(scene, { key, src }) {
    super(scene);
    this.image(key, src);

    this._key = key;
  }

  /**
   * Return promise because of asynchronous
   */
  start(): Promise<string> {
    super.start();
    if (this.textureManager.exists(this._key)) return Promise.resolve(this._key)
      
    return new Promise((resolve, reject) => {
      this.on("filecomplete", () => {
        resolve(this._key);
      });
    });
  }
}
