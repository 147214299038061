<template>
  <div class="designScreen">
    <codementum-loader ref="designSceneLoading" :showOnInit="true"></codementum-loader>
    <div
      :id="'designScreen-' + pageNumber"
      v-for="({pageNumber}) in $store.state.workspace.session.screen.pages"
      :key="pageNumber"
      v-show="pageNumber === showingScreenPage"
      @drop="dropHandler"
      @dragover="e => e.preventDefault()"
      oncontextmenu="return false;"
    ></div>
  </div>
</template>

<script>
import * as Phaser from "phaser";
import { mapState,  mapMutations, mapActions, mapGetters } from "vuex";
import { Design } from "@/types/screen/edit-mode/designScene.ts";
export default {
  name: "DesignScreen",
  data: () => {
    return {
      designList: {},
      scene: null,
      innerHeight: window.innerHeight,
      phaserInstances: []
    };
  },
  computed: {
    ...mapState("app", ["layout"]),
    ...mapGetters("workspace/session", [
      "screenPageLength",
      "showingScreenPage",
      "showingDesignPage",
      "allDesignPages"
    ]),
    gameConfig() {
      return {
        width: this.innerHeight * 0.65 * 0.56 - 19,
        height: this.innerHeight * 0.65 - 19,
        type: Phaser.AUTO,
        parent: "designScreen-" + this.showingScreenPage,
        scene: [Design],
        scale: {
          mode: Phaser.Scale.NONE
        },
        physics: {
          default: "arcade",
          arcade: {
            debug: true,
            gravity: { x: 0, y: 0 }
          }
        },
        canvasStyle: "border-radius:20px;"
      };
    }
  },
  mounted() {
    window.addEventListener("resize", this.resize, false);
  },
  methods: {
    ...mapActions("workspace/session", [
      "registerDesignElement",
      "registerSound",
      "deleteDesignElement",
      "getUniqueIdForDesignElement"
    ]),
     ...mapMutations("workspace/session", ["setWaitForFetching"]),
    resize() {
      this.innerHeight = window.innerHeight;
      this.game.scale.resize(this.gameConfig.width, this.gameConfig.height);
    },
    async dropHandler(e) {
      e.preventDefault();
      const design = this.scenes[0];
      if (e.dataTransfer.getData("newItem")) {
        if (e.dataTransfer.getData("plugin")) {
          const plugin = JSON.parse(e.dataTransfer.getData("item"));
          this.addPlugin(plugin);
        } else if (e.dataTransfer.getData("type") === "sound") {
          this.registerSound(JSON.parse(e.dataTransfer.getData("item")));
          // open sound settings window
          this.$root.$emit("workspace/settings::plugin-sound", true);
        } else {
          const element = JSON.parse(e.dataTransfer.getData("item"));
          //console.log("dropped", element);
          if (e.dataTransfer.lastSession !== true)
            element.name = await this.getUniqueIdForDesignElement(element.name);
          await design.addDesignElement(element, this, element.design.type);
          //console.log("finished", element);
          return Promise.resolve(element);
        }
      }
    },
    // Phaser calls this function when an object is clicked.
    selectElement({ element, /* settings, type */ }) {
      this.$root.$emit("workspace/settings::targetId", element.name);
    },
    addDesignElement({ k, o, /* designElement */ }) {
      this.registerDesignElement({ targetId: k, gameObject: o });
    },
    addSound() {
      return 0;
    },
    async initDesignPage(designPage) {
      const keys = Object.keys(designPage);
      if (!keys || !keys.length) return;
      // check last session design elements
      const waterfall = keys
        .sort(
          (designKey, designKey2) =>
            designPage[designKey].index - designPage[designKey2].index
        )
        .reduce(async (acc, designKey) => {
          const el = designPage[designKey];
          if (!el.canvas && el.lastSession) {
            // it means it gets from last session so there is no reference in phaser.
            this.deleteDesignElement(designKey);
            const fakeDragEvent = {
              preventDefault: () => 0,
              dataTransfer: {
                dropEffect: "copy",
                effectAllowed: "all",
                lastSession: true, // for disable unique key
                getData: key => {
                  if (key === "newItem") {
                    return true;
                  } else if (key === "type") {
                    return el.designElement.type;
                  } else if (key === "item") {
                    return JSON.stringify({
                      ...el.designElement,
                      settings: el
                    });
                  }
                }
              }
            };
            return acc
              .then(() => {
                this.setWaitForFetching(true);
              })
              .then(() => this.dropHandler(fakeDragEvent));
          } else return acc;
        }, Promise.resolve(true));
      return await waterfall.then(() => {
        this.setWaitForFetching(false);
      });
    }
  },
  watch: {
    showingScreenPage: {
      immediate: true,
      handler(pageNumber, oldPageNumber) {
        if (!pageNumber) return;

        if (oldPageNumber) {
          // check page is removed, so delete phaserInstance if exist.
          const findIndex = this.allDesignPages.findIndex(
            p => p.pageNumber === oldPageNumber
          );
          if (findIndex === -1 && this.phaserInstances[oldPageNumber]) {
            // means deleted and must be delete the phaser instance.
            delete this.phaserInstances[oldPageNumber];
          }
        }

        this.$nextTick(() => {
          this.$refs.designSceneLoading.show();

          if (this.phaserInstances[pageNumber]) {
            this.scenes = this.phaserInstances[pageNumber].scene.scenes;
            this.$refs.designSceneLoading.hide();
            return;
          }

          this.game = this.phaserInstances[pageNumber] = new Phaser.Game(this.gameConfig);
          this.scenes = this.phaserInstances[pageNumber].scene.scenes;
          this.phaserInstances[pageNumber].events.on("ready", async () => {
            await this.initDesignPage(this.showingDesignPage);
            this.$refs.designSceneLoading.hide();
          });
        });
      }
    },
    "$store.state.workspace.session.designAndCoding.activeTab"(mode) {
      if (mode === "DESIGN") this.$emit("DESIGN");
      else this.$emit("STOP");
    }
  }
};
</script>

<style lang="scss" scoped>
.designScreen {
  canvas,
  .loading {
    border-bottom-left-radius: 2vh;
    border-bottom-right-radius: 2vh;
    border-radius: 20px !important;
  }
}
</style>
