import * as Phaser from "phaser";

/**
 * A InfoBox Game Objects
 *
 * Shows title and content in a box.
 *
 * - Events:
 *
 *  - `$emit(InfoBox.CLOSE_ALL_INFO_BOX,callback)` close all opened info box.
 */
export class InfoBox extends Phaser.GameObjects.Container {
  _content: string;
  _title: any;
  static CLOSE_ALL_INFO_BOX = Symbol("InfoBox.CLOSE_ALL_INFO_BOX");
  /**
   *
   * @param scene The Scene to which this Game Object belongs. A Game Object can only belong to one Scene at a time.
   * @param x The horizontal position of this Game Object in the world. Default 0.
   * @param y The vertical position of this Game Object in the world. Default 0.
   * @param children An optional array of Game Objects to add to this Container.
   */
  constructor(scene, x = 0, y = 0, { title, content }) {
    super(scene, x, y);
    this.scene.add.existing(this);
    this._title = title;
    this._content = content;

    const frame = this.scene.add.rectangle(0, 0, 200, 300, 0xffffff, 0.7);
    const style = {
      color: "#000",
      textSize: 15
    };
    let titleText = InfoBox.alignCenterByObject(
      frame,
      this.scene.add.text(0, 0, this._title, style)
    );

    let contentText = InfoBox.alignCenterByObject(
      frame,
      this.scene.add.text(0, 0, this._content, style),
      25
    );

    let closeButton = InfoBox.alignCenterByObject(
      frame,
      this.scene.add.text(0, 0, "Close", {
        ...style,
        padding: {
          left: 5,
          right: 5
        },
        backgroundColor: "#fff000"
      }),
      titleText.height + contentText.height + 5
    ).setInteractive();

    closeButton.on("pointerdown", () => {
      this.destroy();
      this.setVisible(false);
      frame.destroy();
      titleText.destroy();
      contentText.destroy();
      closeButton.destroy();
    });

    frame.setSize(
      frame.width,
      titleText.height + contentText.height + closeButton.height + 15
    );

    this.add([frame, titleText, contentText, closeButton]);

    this.setDepth(20000);
    this.setVisible(true);

    this.scene.events.on(InfoBox.CLOSE_ALL_INFO_BOX, () => {
      this.destroy();
      this.setVisible(false);
      frame.destroy();
      titleText.destroy();
      contentText.destroy();
      closeButton.destroy();
    });
  }

  /**
   * Aligns child object to the center of parent object.
   * @param parent Parent game object
   * @param child Child game object in the parent game object.
   * @param y Distance to border.
   */
  static alignCenterByObject(parent, child, y = 5) {
    child.setX(parent.getTopCenter().x - child.width / 2);
    child.setY(parent.getTopCenter().y + y);
    return child;
  }
}
