import DesignElement from "../designElement";
import settings from "../settings";
export default [
  new DesignElement({
    info: {
      title: "button",
      img: "/img/assets/components/button.png"
    },
    structure: {
      name: "button",
      displayName: "Button",
      design: {
        type: "button"
      }
    },
    settings: [
      ...settings.content,
      ...settings.position,
      ...settings.size,
      ...settings.alignment
    ]
  }),
  new DesignElement({
    info: {
      title: "text",
      img: "/img/assets/components/text.png"
    },
    structure: {
      name: "text",
      displayName: "Text",
      design: {
        type: "text"
      }
    },
    settings: [
      ...settings.content,
      ...settings.position,
      ...settings.size,
      ...settings.alignment
    ]
  }),
  new DesignElement({
    info: {
      title: "timer",
      img: "/img/assets/components/timer.png"
    },
    structure: {
      name: "timer",
      displayName: "Timer",
      design: {
        type: "timer"
      }
    },
    settings: [...settings.position, ...settings.size, ...settings.alignment]
  }),
  new DesignElement({
    info: {
      title: "Color Grid",
      img: "/img/assets/components/colorgrid.png"
    },
    structure: {
      name: "colorgrid",
      design: {
        type: "colorgrid"
      }
    },
    settings: [
      {
        label: "Row Count",
        inputType: "number",
        importKey: "col",
        get(gameObject: Phaser.GameObjects.Container) {
          return gameObject.getData("col");
        },
        set(gameObject: Phaser.GameObjects.Container, value) {
          // @ts-ignore
          gameObject.initialize(value, gameObject.getData("col"));
        }
      },
      {
        label: "Column Count",
        inputType: "number",
        importKey: "row",
        get(gameObject: Phaser.GameObjects.Container) {
          return gameObject.getData("row");
        },
        set(gameObject: Phaser.GameObjects.Container, value) {
          // @ts-ignore
          gameObject.initialize(gameObject.getData("row"), value);
        }
      },
      ...settings.position,
      ...settings.size,
      ...settings.alignment
    ]
  }),
  new DesignElement({
    info: {
      title: "Random Images",
      img: "/img/assets/components/randomimages.png"
    },
    structure: {
      name: "randomimages",
      design: {
        type: "randomimages"
      }
    },
    settings: [...settings.position, ...settings.size, ...settings.alignment]
  }),
  new DesignElement({
    info: {
      title: "Image Puzzle",
      img: "/img/assets/components/randomimages.png"
    },
    structure: {
      name: "imagepuzzle",
      design: {
        type: "imagepuzzle"
      }
    },
    settings: [
      {
        label: "Row Count",
        inputType: "number",
        step: 2,
        importKey: "col",
        get(gameObject: Phaser.GameObjects.Container) {
          return gameObject.getData("col");
        },
        set(gameObject: Phaser.GameObjects.Container, value) {
          // @ts-ignore
          gameObject.initialize(value, gameObject.getData("col"));
        }
      },
      {
        label: "Column Count",
        inputType: "number",
        importKey: "row",
        get(gameObject: Phaser.GameObjects.Container) {
          return gameObject.getData("row");
        },
        set(gameObject: Phaser.GameObjects.Container, value) {
          // @ts-ignore
          gameObject.initialize(gameObject.getData("row"), value);
        }
      },
      ...settings.position,
      ...settings.size,
      ...settings.alignment
    ]
  })
];
