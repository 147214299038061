<script>
import WorkspaceContainer from "commonsfrontend/src/maker/vue-files/workspace/workspaceContainer";

import {
  DesignAndCodingContainer,
  ScreenContainer,
  SettingsContainer
} from "./sections/index";

export default {
  extends: WorkspaceContainer,
  components: {
    DesignAndCodingContainer,
    ScreenContainer,
    SettingsContainer
  }
};
</script>
