/**
 * @param time as miliseconds.
 */
export default function wait(time): Promise<Boolean> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
}
