import jmespath from "jmespath";

export default function(showingDesignPage: any, requiredDesignelements: any) {
  const checkCounts = {
    found: [],
    notFound: []
  };

  const usedDesignElements = Object.keys(showingDesignPage).reduce(
    (acc, targetId: any) => {
      const gameObject = showingDesignPage[targetId];
      const designElement = gameObject?.data?.values?.designElement;
      if (!designElement) return acc;

      acc.push(designElement);
      return acc;
    },
    []
  );

  requiredDesignelements.contents.forEach((check: any) => {
    let found = jmespath.search(
      usedDesignElements,
      "[?design.type=='" + check.design.type + "']"
    );
    if (!found) {
      checkCounts.notFound.push(check.design.msg);
      return;
    }

    if (found && check.design.tags) {
      found = jmespath.search(usedDesignElements, "[*].design.tags");

      const matchs = check.design.tags.reduce((acc, tag) => {
        if (!acc) return acc;

        let tagsI = found.length;
        while (tagsI--) {
          const tags = found[tagsI];
          if (!tags.includes(tag)) {
            found.splice(tagsI, 1);
          }
        }

        if (!found.length) return false;
        return true;
      }, true);

      if (!matchs) {
        checkCounts.notFound.push(check.design.msg);
        return;
      }
    }

    if (check.limit) {
      let length = found.length;
      if (check.limit) {
        if (length < 1 || (check.limit !== -1 && check.limit !== length)) {
          checkCounts.notFound.push(check.design.msg);
          return;
        }
      }
    }

    checkCounts.found.push(check.design.msg);
    return;
  });
  if (checkCounts.notFound.length) {
    throw {
      err: "DesignElementsNotFound",
      elements: checkCounts
    };
    return;
  } else return checkCounts;
}
