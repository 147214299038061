import DesignElement from "../designElement";

export default [
  new DesignElement({
    info: {
      title: "Jump 01",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "cartoon_boing_jump_01",
      file: "/sounds/cartoon_boing_jump_01.mp3"
    }
  }),
  new DesignElement({
    info: {
      title: "Jump 15",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "cartoon_boing_jump_15",
      file: "/sounds/cartoon_boing_jump_15.mp3"
    }
  }),
  new DesignElement({
    info: {
      title: "Enlarge",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "cartoon-enlarge",
      file: "/sounds/Cartoon-Enlarge.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "Slip",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "cartoon-slip",
      file: "/sounds/Cartoon-Slip.wav"
    }
  }),
  ,
  new DesignElement({
    info: {
      title: "DM-CGS-1",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-1",
      file: "/sounds/DM-CGS-1.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-2",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-2",
      file: "/sounds/DM-CGS-2.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-3",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-3",
      file: "/sounds/DM-CGS-3.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-4",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-4",
      file: "/sounds/DM-CGS-4.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-5",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-5",
      file: "/sounds/DM-CGS-5.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-6",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-6",
      file: "/sounds/DM-CGS-6.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-7",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-7",
      file: "/sounds/DM-CGS-7.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-8",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-8",
      file: "/sounds/DM-CGS-8.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-9",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-9",
      file: "/sounds/DM-CGS-9.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-10",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-10",
      file: "/sounds/DM-CGS-10.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-11",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-11",
      file: "/sounds/DM-CGS-11.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-12",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-12",
      file: "/sounds/DM-CGS-12.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-13",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-13",
      file: "/sounds/DM-CGS-13.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-14",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-14",
      file: "/sounds/DM-CGS-14.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-15",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-15",
      file: "/sounds/DM-CGS-15.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-16",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-16",
      file: "/sounds/DM-CGS-16.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-17",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-17",
      file: "/sounds/DM-CGS-17.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-18",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-18",
      file: "/sounds/DM-CGS-18.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-19",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-19",
      file: "/sounds/DM-CGS-19.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-20",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-20",
      file: "/sounds/DM-CGS-20.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-21",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-21",
      file: "/sounds/DM-CGS-21.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-22",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-22",
      file: "/sounds/DM-CGS-22.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-23",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-23",
      file: "/sounds/DM-CGS-23.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-24",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-24",
      file: "/sounds/DM-CGS-24.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-25",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-25",
      file: "/sounds/DM-CGS-25.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-26",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-26",
      file: "/sounds/DM-CGS-26.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-27",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-27",
      file: "/sounds/DM-CGS-27.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-28",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-28",
      file: "/sounds/DM-CGS-28.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-29",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-29",
      file: "/sounds/DM-CGS-29.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-30",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-30",
      file: "/sounds/DM-CGS-30.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-31",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-31",
      file: "/sounds/DM-CGS-31.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-32",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-32",
      file: "/sounds/DM-CGS-32.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-33",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-33",
      file: "/sounds/DM-CGS-33.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-34",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-34",
      file: "/sounds/DM-CGS-34.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-35",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-35",
      file: "/sounds/DM-CGS-35.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-36",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-36",
      file: "/sounds/DM-CGS-36.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-37",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-37",
      file: "/sounds/DM-CGS-37.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-38",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-38",
      file: "/sounds/DM-CGS-38.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-39",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-39",
      file: "/sounds/DM-CGS-39.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-40",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-40",
      file: "/sounds/DM-CGS-40.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-41",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-41",
      file: "/sounds/DM-CGS-41.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-42",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-42",
      file: "/sounds/DM-CGS-42.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-43",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-43",
      file: "/sounds/DM-CGS-43.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-44",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-44",
      file: "/sounds/DM-CGS-44.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-45",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-45",
      file: "/sounds/DM-CGS-45.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-46",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-46",
      file: "/sounds/DM-CGS-46.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-47",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-47",
      file: "/sounds/DM-CGS-47.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-48",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-48",
      file: "/sounds/DM-CGS-48.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-49",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-49",
      file: "/sounds/DM-CGS-49.wav"
    }
  }),
  new DesignElement({
    info: {
      title: "DM-CGS-50",
      img: "/img/assets/components/voice.png"
    },
    structure: {
      design: {
        type: "sound"
      },
      id: "dm-cgs-50",
      file: "/sounds/DM-CGS-50.wav"
    }
  })
];
