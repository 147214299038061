<template>
  <div class="project-properties">
    <div class="header">
      <img alt src="/img/assets/components/voice.png" style="filter: invert(1);" />
      <span>Sounds</span>
    </div>
    <div class="properties">
      <div class="group" v-for="(sound) in soundsList" :key="sound.id">
        <label for="text">{{ sound.title }}</label>
        <div class="setting-buttons">
          <button class="play" @click="playSound(sound, $event)">Play</button>
          <button class="remove" @click="removeSound(sound)">Remove</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters("workspace/session", ["soundsList"])
  },
  methods: {
    ...mapMutations("workspace/session", ["removeSound"]),
    playSound(item) {
      const audio = new Audio(item.file);
      audio.play();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~commonsfrontend/src/maker/style/scss/vars.scss";
.project-properties {
  color: white;
  width: calc(100% * 2.1 / 12);
  height: 100%;
  background-color: #38393a;
  position: relative;
  overflow: auto;

  .selection-title__wrapper,
  .selection-title {
    width: 100%;
    color: #b2b4b7;
    font-size: 12px;
    font-weight: 900;
    display: block;
    margin-bottom: 0.5rem;
  }

  .slimBorder .row {
    color: #d6d6d6;
  }

  .vertical-mode & {
    position: absolute;
    width: 600px;
    bottom: 0;
    height: 56vh;
    right: 0;
  }

  .header {
    background-color: $blackDark;
    padding: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      // filter: invert(1);
      max-height: 25px;
      width: auto;
      display: block;
    }

    span {
      margin-left: 10px;
      color: #fff;
      font-size: 1rem;
      font-weight: 700;
      flex-basis: 100%;
    }

    .arrows {
      span {
        color: #b2b4b7;
        cursor: pointer;
        margin-right: 0.5em;
        &:hover {
          color: white;
        }
      }
    }
  }

  .properties {
    padding: 0.5rem 0.5rem 3rem 0.5rem;
    height: calc(100% - 2rem - 14px);
    max-width: 100%;
    // min-height: 100%;
    overflow: auto;
    overflow-x: none;
    scrollbar-width: thin;
    .group {
      margin-bottom: 1rem;

      .setting-buttons {
        display: flex;
        flex-direction: row;
        button {
          display: block;
          margin-right: 1em;
          background-color: $blackLight;
          border: none;
          border-radius: 2px;
          padding: 0.3rem 0.5rem;
          color: #fff;

          &.play {
            border: 1px solid #23e5a4;
            color: #23e5a4;
          }
          &.remove {
            border: 1px solid #e52384;
            color: rgb(252, 212, 212);
          }
        }
      }

      input {
        display: block;
        width: 100%;
        background-color: $blackLight;
        border: none;
        border-radius: 2px;
        padding: 0.5rem;
        color: #fff;
      }

      &.horizontal {
        display: flex;
        justify-content: space-between;
        align-items: center;

        label {
          width: initial;
        }

        .horizontal-container {
          text-align: right;
        }
      }

      label {
        width: 100%;
        color: #b2b4b7;
        font-size: 12px;
        font-weight: 900;
        display: block;
        margin-bottom: 0.5rem;
      }

      textarea {
        display: block;
        width: 100%;
        background-color: $blackLight;
        border: none;
        border-radius: 2px;
        padding: 0.5rem;
        color: #fff;
        min-height: 100px;
      }

      button {
        border: none;
        background-color: transparent;
      }

      .colors {
        button {
          margin: 0 1rem 1rem 0;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: none;
        }
      }

      .form-control {
        display: block;
        width: 100%;
        background-color: #565656;
        border: none;
        border-radius: 2px;
        padding: 0.5rem;
        color: #fff;
      }
    }
  }

  .btn-delete {
    width: 100%;
    padding: 1rem;
    color: #fff;
    background-color: red;
    position: absolute;
    bottom: 0;
    border: none;
    padding: 1rem;
    font-weight: 500;

    span {
      font-weight: 900;
    }
  }
}
</style>
